// extracted by mini-css-extract-plugin
export var heroSection = "press-and-media-module--heroSection--9fb77";
export var heroSection__button = "press-and-media-module--heroSection__button--a0267";
export var heroSection__card = "press-and-media-module--heroSection__card--49504";
export var heroSection__card__icon = "press-and-media-module--heroSection__card__icon--fff55";
export var heroSection__cards__wrapper = "press-and-media-module--heroSection__cards__wrapper--5da2c";
export var heroSection__subtitle = "press-and-media-module--heroSection__subtitle--20f69";
export var heroSection__title = "press-and-media-module--heroSection__title--105a1";
export var mediaSection = "press-and-media-module--mediaSection--fb8a5";
export var mediaSection__logos__wrapper = "press-and-media-module--mediaSection__logos__wrapper--0b3b0";
export var mediaSection__title = "press-and-media-module--mediaSection__title--2cf2c";