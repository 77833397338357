import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";
import RelatedBlogs from "../components/RelatedBlogs";
import YellowSection from "../components/YellowSection";

// Assets
import FileDownloadIcon from "../images/icons/file-download.svg";
import FileDownloadCircleIcon from "../images/icons/file-download-circle.svg";
import logo1 from "../images/press/logo-1.png";
import logo2 from "../images/press/logo-2.png";
import logo3 from "../images/press/logo-3.png";
import logo4 from "../images/press/logo-4.png";
import logo5 from "../images/press/logo-5.png";
import logo6 from "../images/press/logo-6.png";
// Styles
import * as styles from "../styles/pages/press-and-media.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";

const mediaLogos = [logo1, logo2, logo3, logo4, logo5, logo6];

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.heroSection}>
      <div className="sectionContainer">
        <motion.div {...revealContainerWithChildrenProps}>
          <motion.h1
            className={styles.heroSection__title}
            variants={revealVariant}
          >
            {t("press.hero.title")}
          </motion.h1>
          <motion.p
            className={styles.heroSection__subtitle}
            variants={revealVariant}
          >
            {t("press.hero.subtitle")}
          </motion.p>
          <motion.a
            variants={revealVariant}
            href="/files/Zeal-Media-Kit.zip"
            className={`button button--primary ${styles.heroSection__button}`}
          >
            {t("press.hero.button")}
            <span>
              <FileDownloadIcon />
            </span>
          </motion.a>
        </motion.div>
        <motion.div className={styles.heroSection__cards__wrapper}>
          <motion.a
            href="/files/Zeal-Logo.zip"
            className={styles.heroSection__card}
            variants={revealVariant}
            {...revealContainerProps}
          >
            <div className={styles.heroSection__card__icon}>
              <FileDownloadCircleIcon />
            </div>
            <div>
              <StaticImage
                height={100}
                formats={["auto", "webp", "avif"]}
                placeholder="none"
                src="../images/press-and-media-logo.svg"
                alt="Zeal Logo"
              />
            </div>
            <p>{t("press.zealLogo.title")}</p>
          </motion.a>
          <motion.a
            href="/files/Zeal-UI-Screens.zip"
            className={styles.heroSection__card}
            variants={revealVariant}
            {...revealContainerProps}
          >
            <div className={styles.heroSection__card__icon}>
              <FileDownloadCircleIcon />
            </div>
            <div>
              <StaticImage
                height={100}
                formats={["auto", "webp", "avif"]}
                placeholder="none"
                src="../images/press-and-media-screens.png"
                alt="Product Screen"
              />
            </div>
            <p>{t("press.productsScreens.title")}</p>
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

const MediaSection = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.mediaSection}>
      <div className="sectionContainer">
        <motion.h2
          className={styles.mediaSection__title}
          variants={revealVariant}
        >
          {t("press.media.title")}
        </motion.h2>

        <motion.div
          className={styles.mediaSection__logos__wrapper}
          {...revealContainerWithChildrenProps}
        >
          {mediaLogos.map((logo, index) => (
            <motion.img
              variants={revealVariant}
              key={index}
              src={logo}
              alt="Media Logo"
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

const PressAndMediaPage = ({ data: { relatedBlogs } }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Layout>
      <HeroSection />
      <MediaSection />
      <RelatedBlogs
        title={t("press.latestBlog.title")}
        description={t("press.latestBlog.description")}
        blogs={relatedBlogs.nodes}
      />
      <YellowSection
        title={t("press.yellowSection.title")}
        description={t("press.yellowSection.description")}
        to="/contact-us"
        state={{ referrer: location.href }}
        btnText={t("press.yellowSection.button")}
      />
    </Layout>
  );
};

export default PressAndMediaPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "press-and-media"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    relatedBlogs: allGhostPost(
      filter: { tags: { elemMatch: { name: { eq: "News" } } } }
      limit: 3
    ) {
      nodes {
        title
        feature_image
        url
        excerpt
        created_at(formatString: "MMM YYYY")
        reading_time
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return (
    <Seo isArabic={isArabic} title={isArabic ? "الصحافة" : "Press & Media"} />
  );
};
