import React from "react";
import CookieConsent from "react-cookie-consent";
import Lottie from "lottie-react";
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next";

import Header from "./Header";
import Footer from "./Footer";

import eatingCookieAnimationData from "../images/lottie/eating-cookie.json";

const Layout = ({ children, hasNavbarOnlyWhite }) => {
  const { t } = useTranslation();

  return (
    <div className="layout">
      <Header isOnlyWhite={hasNavbarOnlyWhite} />
      <main>{children}</main>
      <Footer />
      <CookieConsent
        expires={150}
        enableDeclineButton
        disableStyles
        buttonWrapperClasses="buttons-wrapper"
        buttonText={t("cookie.button.accept")}
        declineButtonText={t("cookie.button.decline")}
      >
        <Lottie animationData={eatingCookieAnimationData} />
        <strong>{t("cookie.title")}</strong>
        <p><Trans i18nKey="cookie.description"
          values={{ link: t("cookie.description.link") }} components={[<Link
            to="/term-and-conditions" />]} />
        </p>
      </CookieConsent>
    </div>
  );
};

export default Layout;
