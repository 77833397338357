import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";

// Styles
import * as styles from "../styles/components/DragCards.module.css";

// Utils
import { revealContainerWithChildrenProps } from "../utils/animation-variants";

const DragCards = ({ children, className }) => {
  const [{ startX, startScrollLeft, isDragging }, setDragStart] = useState({
    startX: undefined,
    startScrollLeft: undefined,
    isDragging: false,
  });
  const containerRef = useRef();
  useEffect(() => {
    containerRef.current.scrollLeft = 0;
  }, [containerRef]);
  const handleMouseDown = (e) => {
    setDragStart({
      startX: e.pageX - containerRef.current.offsetLeft,
      startScrollLeft: containerRef.current.scrollLeft,
      isDragging: true,
    });
  };
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = x - startX;
    containerRef.current.scrollLeft = startScrollLeft - walk;
  };

  return (
    <div
      className={styles.flashcards}
      onMouseDown={handleMouseDown}
      onMouseUp={() => setDragStart((prev) => ({ ...prev, isDragging: false }))}
      onMouseMove={handleMouseMove}
      role="button"
      tabIndex="0"
    >
      <motion.div
        className={`${styles.sectionContainer} ${className}`}
        ref={containerRef}
        {...revealContainerWithChildrenProps}
      >
        {children}
      </motion.div>
    </div>
  );
};
export default DragCards;
