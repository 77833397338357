// extracted by mini-css-extract-plugin
export var DragCards = "RelatedBlogs-module--DragCards--05f8f";
export var card = "RelatedBlogs-module--card--12f12";
export var card__content = "RelatedBlogs-module--card__content--99353";
export var card__excerpt = "RelatedBlogs-module--card__excerpt--aef81";
export var card__footer = "RelatedBlogs-module--card__footer--06f5f";
export var card__image = "RelatedBlogs-module--card__image--9cab8";
export var card__title = "RelatedBlogs-module--card__title--352f5";
export var description = "RelatedBlogs-module--description--7d712";
export var header = "RelatedBlogs-module--header--b6c43";
export var sectionContainer = "RelatedBlogs-module--sectionContainer--4fcc0";
export var title = "RelatedBlogs-module--title--f39f7";