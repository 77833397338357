import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const SEO = ({ isArabic, title, description, pathname, children }) => {
  const { image, favicon } = useStaticQuery(graphql`
    query {
      image: file(name: { regex: "/og-image/" }) {
        publicURL
      }
      favicon: file(name: { regex: "/favicon/" }) {
        publicURL
      }
    }
  `);
  const siteUrl = "https://myzeal.app";
  const seo = {
    title: title || (isArabic ? "زيل" : "Zeal"),
    description:
      description ||
      (isArabic
        ? "أسهل طريقة للحصول على مكافآت مع جميع معاملات البطاقة"
        : "The easiest way to get rewarded with all card transactions"),
    image: `${siteUrl}${image.publicURL}`,
    url: `${siteUrl}${pathname || ``}`,
  };

  return (
    <>
      <title>
        {seo.title} | {isArabic ? "زيل" : "Zeal"}
      </title>
      <meta
        name="title"
        content={`${seo.title} | ${isArabic ? "زيل" : "Zeal"}`}
      />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta
        property="og:title"
        content={`${seo.title} | ${isArabic ? "زيل" : "Zeal"}`}
      />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      {/* Twitter  */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={siteUrl} />
      <meta
        property="twitter:title"
        content={`${seo.title} | ${isArabic ? "زيل" : "Zeal"}`}
      />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />
      <link rel="icon" href={favicon.publicURL} />
      {children}
    </>
  );
};
export default SEO;
