import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";

// Styles
import * as styles from "../styles/components/YellowSection.module.css";

// Utils
import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";

const MotionLink = motion(Link);

const HeroGradient = ({ title, description, btnText, to, url }) => {
  const location = useLocation();
  return (
    <section className={styles.sectionContainer}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.content} sectionContainer`}
      >
        <motion.h2 variants={revealVariant} className={styles.title}>
          {title}
        </motion.h2>
        <motion.p variants={revealVariant} className={styles.description}>
          {description}
        </motion.p>
        {to ? (
          <MotionLink
            variants={revealVariant}
            to={to}
            state={{ referrer: location.href }}
            className={`${styles.button} button button--secondary`}
          >
            {btnText}
          </MotionLink>
        ) : (
          <motion.a
            variants={revealVariant}
            href={url}
            target="_blank"
            rel="noreferrer"
            className={`${styles.button} button button--secondary`}
          >
            {btnText}
          </motion.a>
        )}
      </motion.div>
    </section>
  );
};

export default HeroGradient;
