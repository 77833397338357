import * as React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { GatsbyImage } from 'gatsby-plugin-image';

// Components
import DragCards from "./DragCards";

// Styles
import * as styles from "../styles/components/RelatedBlogs.module.css";

// Utils
import {
	revealContainerProps,
	revealVariant,
} from "../utils/animation-variants";

const RelatedBlogs = ({ title, description, blogs }) => {
	const { language } = useI18next();
	if (language === "ar") {
		return null;
	}
	return (
		<section className={`${styles.sectionContainer} sectionContainer`}>
			<div className={styles.header}>
				<motion.h2
					{...revealContainerProps}
					variants={revealVariant}
					className={styles.title}
				>
					{title}
				</motion.h2>
				<motion.p
					{...revealContainerProps}
					variants={revealVariant}
					className={styles.description}
				>
					{description}
				</motion.p>
			</div>
			<DragCards className={styles.DragCards}>
				{blogs.map((blog) => (
					<motion.a
						variants={revealVariant}
						href={blog.url}
						target="_blank"
						rel="noopener noreferrer"
						key={blog.url}
						className={styles.card}
					>
						<div className={styles.card__image}>
							<img src={blog.feature_image} alt={blog.title} />
						</div>
						<div className={styles.card__content}>
							<h3 className={styles.card__title}>{blog.title}</h3>
							<p className={styles.card__excerpt}>{blog.excerpt}</p>
							<p className={styles.card__footer}>
								{blog.created_at} | {blog.reading_time} mins read
							</p>
						</div>
					</motion.a>
				))}
			</DragCards>
		</section>
	);
};

export default RelatedBlogs;
